<template>
  <div class="page-admin-users" v-if="user && user.roles.includes('super')">
    <div class="page-admin-users-header"></div>
    <div class="page-admin-users-container">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
  export default {
    data: () => {
      return {
        user: null,
      };
    },
    methods: {
      linkIsActive(input) {
        return this.$global.linkInclude(this.$route, input);
      },
    },
    mounted: async function () {
      this.user = await this.$store.state.userProfile;
    },
  };
</script>

<style lang="scss">
  @import "@/assets/styles/vars.scss";
  .page-admin-users {
    position: relative;
    &-header {
      display: block;
      width: 100%;
      border-bottom: solid 1px $alto;
      white-space: nowrap;
      overflow-x: auto;
      .wrp-btn {
        text-align: center;
        display: inline-block;
        min-width: 50%;
        a {
          display: block;
          padding: $mpadding;
          background: $white;
        }
        &:hover {
          color: $primary-color;
          font-weight: bold;
        }
      }
    }
    @media (min-width: $tablet_width) {
      &-header {
        position: sticky;
        top: 49px;
        display: flex;
        flex: 1;
        justify-content: space-around;
        z-index: 1;
        .wrp-btn {
          width: 100%;
          text-align: center;
        }
      }
    }
  }
</style>
